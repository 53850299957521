import { useState, useContext, useEffect,useCallback } from "react";
import {
  Alert,
  Modal,
  View,
  Text,
  SafeAreaView,
  Image,
  ScrollView,
  Pressable,
  ImageBackground,
  TextInput,
  StyleSheet,
  TouchableOpacity,
} from "react-native";



import axios from "axios";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Ionicons from "@expo/vector-icons/Ionicons";

import nivel1 from './../../assets/niveles/nivel1.png';
import nivel2 from './../../assets/niveles/nivel2.png';
import nivel3 from './../../assets/niveles/nivel3.png';
import Monedas from './../../assets/botones/monedas.png';
import Req from './../../assets/botones/icon_req.png';
import Retirocash from './../../assets/botones/retiro_cash.png';
import { AuthContext } from './../../context/AuthContext';
import { useFocusEffect } from '@react-navigation/native';

const url = "https://apibacked.cooltask.homes/public/usuarios";
const urlplan = "https://apibacked.cooltask.homes/public/usuariosplanplata";

const AgenteInterno = ({ navigation }) => {
  
  const [data, setData] = useState([]);
  
  const [cargando, setCargando] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  const {userInfo} = useContext(AuthContext);
  const iduser= userInfo[0].id;
  const [saldo, setSaldo] = useState(0);
  const plan = 'Agente Interno';

  console.log(data);

  const peticionGet = async () => {
    await axios.get(url + "/" + iduser).then((response) => {
      setData(response.data[0]);
      setSaldo(response.data[0].Monto); 
    });
  };

  const peticionPut = async () => {
    await axios.put(urlplan + "/" + iduser, { plan}).then((response) => {
      peticionGet();
      setModalVisible(false);
      navigation.navigate('planprocesado');
    });
  };

  console.log(iduser);
  let deposito = saldo *1;

useFocusEffect(
  useCallback(() => {
    peticionGet();
  }, [])
);


  return (
    <ScrollView>
    <View style={styles.container}>
      
    <View style={styles.listemguia}>
    <Text
            style={{
              color: '#000',
              fontSize: 14,
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            Requisitos
          </Text>
       
          <View style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            
            <Text
              style={{
                color: '#2c2c2c',
                fontSize: 12,
              
              }}>
             Depositar 100 USDT       
            </Text>
            <Image
                source={Req}
                style={{ width: 70, height: 70, marginTop:-32 }}
              />

          </View>

       </View>

       <View style={styles.listemguia}>
    <Text
            style={{
              color: '#000',
              fontSize: 14,
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            Ganancias
          </Text>
       
          <View style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            
            <Text
              style={{
                color: '#2c2c2c',
                fontSize: 12,
               
              
              }}>
              Ganara el 5.3% diario de su inversión más los 15 usdt del bono        
            </Text>
            <Image
                source={Monedas}
                style={{ width: 70, height: 70, marginTop:-32 }}
              />

          </View>

       </View>

       <View style={styles.listemguia}>
    <Text
            style={{
              color: '#000',
              fontSize: 14,
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            Retiros
          </Text>
       
          <View style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            
            <Text
              style={{
                color: '#2c2c2c',
                fontSize: 12,
              
              }}>
              Siempre podras retirar         
            </Text>
            <Image
                source={Retirocash}
                style={{ width: 70, height: 70, marginTop:-32 }}
              />

          </View>
       </View>

       <View style={styles.listemboton}>
       <TouchableOpacity onPress={() => setModalVisible(!modalVisible)}
        style={{
          padding: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        >
        <Text
          style={{
            color: '#fff',
            fontSize: 13,
            textAlign: 'center',
            fontWeight: 'bold'
          }}>
          Convertirme en Agente Interno
        </Text>
        

        <MaterialIcons name="arrow-forward-ios" size={18} color="#fff" />
      </TouchableOpacity>
      </View>
       
      <View style={styles.centeredView}>
          <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            statusBarTranslucent={true}
            onRequestClose={() => {
              Alert.alert("Modal has been closed.");
              setModalVisible(!modalVisible);
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                {deposito < 100 ?
                  <View>
                    <Text
                      style={{
                        fontSize: 15,
                        color: "#fff",
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginBottom: 20
                      }}
                    >
                      No posee el saldo suficiente para ser Agente Interno
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Pressable
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => setModalVisible(!modalVisible)}
                      >
                        <Text style={styles.textStyle}>Cerrar</Text>
                      </Pressable>
                      <Pressable
                        style={[styles.button, styles.buttonfiltrar]}
                        onPress={() => { navigation.navigate('Deposito'); setModalVisible(false) }}
                      >
                        <Text style={styles.textStyle}>Añadir fondos</Text>
                      </Pressable>
                    </View>
                  </View>
                  :
                  <View>
                    <Text
                      style={{
                        fontSize: 15,
                        color: "#fff",
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginBottom: 20
                      }}
                    >
                      ¿Desea convertirte en Agente Interno?
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Pressable
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => setModalVisible(!modalVisible)}
                      >
                        <Text style={styles.textStyle}>Mas tarde</Text>
                      </Pressable>
                      <Pressable
                        style={[styles.button, styles.buttonfiltrar]}
                        onPress={peticionPut}
                      >
                        <Text style={styles.textStyle}>Comenzar</Text>
                      </Pressable>
                    </View>
                  </View>
                }
              </View>
            </View>
          </Modal>
        </View>
    
    </View>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    marginTop:11,
  },
  listemguia: {
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 25,
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 15,
  },
  listemboton: {
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 25,
    backgroundColor: '#fd8029',
    padding: 10,
    borderRadius: 15,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    backgroundColor: "#367ff5",
    borderRadius: 10,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 10,
    padding: 10,
    elevation: 2,
    margin: 5,
  },

  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#000",
  },
  buttonfiltrar: {
    backgroundColor: "#0a1d60",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    width: 100,
  },
  modalText: {
    marginBottom: 50,
    paddingHorizontal: 10,
    textAlign: "center",
    fontSize: 15,
    fontWeight: "bold",
  },
});

export default AgenteInterno ;
