import { useState, useContext, useEffect } from "react";
import {
  Alert,
  Modal,
  View,
  Text,
  SafeAreaView,
  Image,
  ScrollView,
  Pressable,
  ImageBackground,
  TextInput,
  StyleSheet,
  TouchableOpacity,
} from "react-native";



import axios from "axios";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Ionicons from "@expo/vector-icons/Ionicons";
import Monedas from './../../assets/botones/monedas.png';
import { AuthContext } from '../../context/AuthContext';
import nivel1 from './../../assets/niveles/nivel1.png';
import nivel2 from './../../assets/niveles/nivel2.png';
import nivel3 from './../../assets/niveles/nivel3.png';

const Guia = ({ navigation }) => {
  
  const [data, setData] = useState([]);
  
  const [cargando, setCargando] = useState(true);

  const { userInfo } = useContext(AuthContext);



  return (
    <ScrollView>
    <View style={styles.container}>
      
    <View style={styles.listemguia}>
          <Text
            style={{
              color: '#000',
              fontSize: 14,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              marginBottom: 10,
            }}>
            Agente externo
          </Text>


          <View style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            <Text
              style={{
                color: '#2c2c2c',
                fontSize: 12,
                marginTop: 9,
              }}>
              Gana el 4% diario de su inversion
              
            </Text>

          
          </View>

       </View>
       <View style={styles.listemguia}>
          <Text
            style={{
              color: '#000',
              fontSize: 14,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              marginBottom: 10,
            }}>
            Agente Interno
          </Text>


          <View style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            <Text
              style={{
                color: '#2c2c2c',
                fontSize: 12,
                marginTop: 9,
              }}>
             Gana el 5.3% diario de su inversion
              
            </Text>

            <Image
              source={nivel3}
              style={{ width: 80, height: 80 }}
            />
          </View>

       </View>
       <View style={styles.listemguia}>
          <Text
            style={{
              color: '#000',
              fontSize: 14,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              marginBottom: 10,
            }}>
            Agente VIP
          </Text>


          <View style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            <Text
              style={{
                color: '#2c2c2c',
                fontSize: 12,
                marginTop: 9,
              }}>
            Gana el 7.2% diario de su inversion
              
            </Text>

            <Image
              source={nivel2}
              style={{ width: 80, height: 80 }}
            />
          </View>

       </View>
       <View style={styles.listemguia}>
          <Text
            style={{
              color: '#000',
              fontSize: 14,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              marginBottom: 10,
            }}>
            Agente Superior
          </Text>


          <View style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            <Text
              style={{
                color: '#2c2c2c',
                fontSize: 12,
                marginTop: 9,
              }}>
            Gana el 9.5% diario de su inversion
              
            </Text>

            <Image
              source={nivel1}
              style={{ width: 80, height: 80 }}
            />
          </View>
          </View>
          <View style={styles.listemguia}>
          <Text
            style={{
              color: '#000',
              fontSize: 14,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              marginBottom: 10,
            }}>
            Agente Senior
          </Text>


          <View style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
            <Text
              style={{
                color: '#2c2c2c',
                fontSize: 12,
                marginTop: 9,
              }}>
            Gana el 13.5% diario de su inversion
              
            </Text>

            <Image
              source={nivel1}
              style={{ width: 80, height: 80 }}
            />
          </View>

          
          </View>
       </View>
      
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    marginTop:11,
  },
  listemguia: {
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 25,
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 15,
  }
});

export default Guia;
